import React from 'react'
import { useSelector } from 'react-redux';

import bgNosotros from '../../../../assets/images/slide-3-des2k.png';
import BannerTOP from '../../../Global/Modules/MediaPush/BannerTop';
import { SecondSection } from '../section-2/SecondSection';
import { ThirdSection } from '../section-3/ThirdSection';

// Custom Hook
import useWindowSize from "../../../../hooks/useWindowSize";

export const FirstSection = () => {

  // Hook windwo size
  const windowSize = useWindowSize();
  // Page
  const page = 'tasaciones';

  // Get actions
  const actions = useSelector((store) => store.actions);

  // Check actions in home and convert in bool
  const actionsInPage = actions.actions?.filter(action => action.section.toLowerCase() === page)[0];
  const hasActionsPage = (actionsInPage) 
      ? Boolean(actionsInPage.section.toLowerCase() === page) 
      : undefined;

  // Filter slider actions and convert in bool
  const sliderAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'slider de imagenes encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasSlider = (sliderAction) 
      ? Boolean(sliderAction.type.toLowerCase() === 'slider de imagenes encabezado') 
      : undefined;
    
  // Filter image actions and convert in bool
  const imageAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'imagen encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasImage = (imageAction)
      ? Boolean(imageAction.type.toLowerCase() === 'imagen encabezado')
      : undefined;
    
  // Filter video actions and convert in bool
  const videoAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'video encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasVideo = (videoAction)
      ? Boolean(videoAction.type.toLowerCase() === 'video encabezado')
      : undefined;

  // Conditional Render to show sections
  const conditionalRedner = () => {
      // has actions in home?
      if (hasActionsPage) {
          // has video?
          if (hasVideo) {
              // has window size > 992
              return (windowSize.width > 992)
                  ? <BannerTOP page={page} actions={actions.actions} />
                  : staticContent();
          // has slider?
          } else if (hasSlider) {
              return <BannerTOP page={page} actions={actions.actions} />
          // has image?
          }else if (hasImage) {
              return <BannerTOP page={page} actions={actions.actions} />
          } else {
              return staticContent();
          }
      } else {
          return staticContent();
      }
  }

  const staticContent = () => {
    return <img className="main__first__section__background__about" src={ bgNosotros } alt="Manifiesto" />
  }

  return (
    <section className="main__first__section__about" id="firstSection">
      <div className="main__first__section__container__about">
        { conditionalRedner() }
        { (!hasSlider || hasImage || hasVideo) ?
          <div className="main__first__section__title__about d-flex align-items-end">
            <h1 className="main__title__about text-uppercase fs-60 montserrat-bold col-8">Construimos hogares.</h1>
          </div> : null }
        <SecondSection />
        <ThirdSection />
      </div>
    </section>
  )
}
