import React , {useEffect} from 'react'


// -- Componentes -- //
import Main from '../components/MediaBlog/Novedades/main'
import Blogs from '../components/MediaBlog/Novedades/blogs'
import { useDispatch } from 'react-redux';

import {getTagsAction, getNoveltiesAction} from '../redux/MediaBlog/noveltiesDucks'

import { Sidebar } from '../components/sidebar/Sidebar';
import { Header } from '../components/header/Header'
import { Whatsapp } from '../components/Whatsapp'
import { FirstSection } from '../components/MediaBlog/Novedades/FirstSection';
import { getActionsAction } from '../redux/actionsDucks';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Novedades(){

    const dispatch = useDispatch() 

    useEffect(() => {
      dispatch(getTagsAction())
      dispatch(getNoveltiesAction())
      dispatch(getActionsAction())
    },[])

    ScrollToTopOnMount()
    return(
        <div className="novedades">
          <Header />
          <div className="d-flex flex-column">
            <div className="layout main__container  p-0 m-0 d-flex ">
              <Sidebar />
              <FirstSection/>
              <Whatsapp />  
            </div>
          </div>
          
        </div>
    )
}

export default Novedades