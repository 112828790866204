import React from 'react'
import { infoItem1, infoItem2 } from './InformationItem'
import imageMap from '../../../../assets/images/main-home-2.jpg'
import { useSelector, useDispatch } from "react-redux";


import Map from '../../../map'
const latitud = {latitud: -34.57565921221931, longitud: -58.420479848902474}

export const ThirdSection = () => {

  const settings  = useSelector((store) => store.settings);

  return (
    <section className="main__third__section m-0">
      <div className="col-12 m-auto">
        <div className="row d-flex flex-column flex-lg-row justify-content-center">
            <div className="col-lg-12 text-center">
              <h3 className="title-map">¿QUERÉS <span>VISITARNOS?</span></h3>
              <p className="location-map">NUEVA YORK 4302, VILLA DEVOTO</p> 
            </div>
        </div>
      </div>
      <div className="col-12">
        <div className="map-about">
          <Map coordenadas={{ geo_lat: -34.6021846, geo_long: -58.5150171}} />
        </div>
      </div>
    </section>
  )
}
