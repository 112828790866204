import React from 'react'

import mapPicture from '../../../../assets/images/map.svg'
import BreadCrumbs from "../../../breadcrumbs";

import Image1 from '../../../../assets/images/sebastian.png'
import Image2 from '../../../../assets/images/diego.png'
// import bgCircle from '../../../../assets/images/elipse_bg_home_primary.svg';


export const SecondSection = () => {
  return (
    <section className="main__second__section__contacto">
      {/* <img className="bgCircle_contacto d-lg-block" src={bgCircle} alt="bgcircle" /> */}
      <div className="main__second__section__container col-12 m-auto">
        <div className="row">
          <div className="col-lg-12 col-10 offset-1 offset-lg-0 col-xl-6 d-flex flex-column">
            <BreadCrumbs
              props={[
                { name: "Home", route: "", location: "" },
                { name: "Nosotros", active:true },
              ]}
            /> 
          </div>
        </div>
        <div className="row p-3 px-4 px-lg-0 justify-content-between">
            <div className="col-xxl-5 col-md-5 pe-lg-5">
              <div className="card-about ms-lg-3">
                  <div className="square"></div>
                  <div className="image position-relative">
                    <img className="w-100" src={Image1} alt="" />
                  </div>
                  <h3>Sebastian Martín Orlandi</h3>
                  <h5>LIC. ADMINISTRACIÓN Y MBA</h5>
                  <p className="sspro-light">
                  Ejecutivo con más de diez años de experiencia liderando y concretando negocios exitosos, con una sólida trayectoria en el desarrollo de estrategias comerciales y nuevos emprendimientos. Cuenta con un amplio recorrido por distintas industrias - Consumo Masivo, Entretenimiento Hogareño, Retail y Real Estate - además de una importante especialización que le otorga gran expertise dentro del sector. Es Licenciado en Administración de Empresas (Universidad del Salvador) y realizó un MBA en la Universidad Torcuato Di Tella, además de un Programa Ejecutivo de Real Estate Management en la misma universidad.               
                  </p>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-5 ps-lg-5">
              <div className="card-about top-offset">
                  <div className="square"></div>
                  <div className="image position-relative">
                    <img className="w-100" src={Image2} alt="" />
                  </div>
                  <h3>Diego Javier Barbaro</h3>
                  <h5>ABOGADO</h5>
                  <p className="sspro-light">
                  Desarrollador Inmobiliario con más de quince años de trayectoria en el mercado. Reconocido por ser un importante generador de negocios a través de vínculos que generan relaciones comerciales a largo plazo. Nació y creció dentro del entorno del Real Estate ya que es hijo de reconocidos profesionales del mundo inmobiliarios del partido de General San Martín. Cuenta con gran expertise en ventas, focalizado en el consumidor final. Es abogado egresado de la Universidad de Buenos Aires y cuenta con un amplio conocimiento y especialización en fideicomisos inmobiliarios.        
                  </p>
            </div>
            </div>
          </div>
      </div>
    </section>

  )
}
