import React from 'react';
import { useSelector } from 'react-redux';

import bgContacto from '../../../../assets/images/slide-3-desk3.png';
import bgCircle from '../../../../assets/images/elipse_bg_home_primary.svg';
import BannerTOP from '../../../Global/Modules/MediaPush/BannerTop';

// Custom Hook
import useWindowSize from '../../../../hooks/useWindowSize';

export const FirstSection = () => {

  // Hook windwo size
  const windowSize = useWindowSize();
  // Page
  const page = 'nosotros';

  // Get actions
  const actions = useSelector((store) => store.actions);

  // Check actions in home and convert in bool
  const actionsInPage = actions.actions?.filter(action => action.section.toLowerCase() === page)[0];
  const hasActionsPage = (actionsInPage) 
      ? Boolean(actionsInPage.section.toLowerCase() === page) 
      : undefined;

  // Filter slider actions and convert in bool
  const sliderAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'slider de imagenes encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasSlider = (sliderAction) 
      ? Boolean(sliderAction.type.toLowerCase() === 'slider de imagenes encabezado') 
      : undefined;
    
  // Filter image actions and convert in bool
  const imageAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'imagen encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasImage = (imageAction)
      ? Boolean(imageAction.type.toLowerCase() === 'imagen encabezado')
      : undefined;
    
  // Filter video actions and convert in bool
  const videoAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'video encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasVideo = (videoAction)
      ? Boolean(videoAction.type.toLowerCase() === 'video encabezado')
      : undefined;

  // Conditional Render to show sections
  const conditionalRedner = () => {
      // has actions in home?
      if (hasActionsPage) {
          // has video?
          if (hasVideo) {
              // has window size > 992
              return (windowSize.width > 992)
                  ? <BannerTOP page={page} actions={actions.actions} />
                  : staticContent();
          // has slider?
          } else if (hasSlider) {
              return <BannerTOP page={page} actions={actions.actions} />
          // has image?
          }else if (hasImage) {
              return <BannerTOP page={page} actions={actions.actions} />
          } else {
              return staticContent();
          }
      } else {
          return staticContent();
      }
  }

  const staticContent = () => {
    return <img className="main__first__section__background__contact" src={ bgContacto } alt="Nosotros" />
  }

  return (
      <section className="main__first__section__contact">
        <div className="main__first__section__container__contact">
          { conditionalRedner() }
          { (!hasSlider || hasImage || hasVideo) ?
              <div className="main__first__section__title__contact d-flex align-items-end justify-content-end">
                <div className="main__first__section__title__container__contact col-10 h-100 d-flex flex-column justify-content-center">
                  <h1 className="main__title__contacto__contact text-uppercase fs-60 montserrat-bold m-auto col-8 col-xl-9 text-right">Sobre nosotros</h1> 
                </div>
              </div> : null }
        </div>
        <img className="bgCircle_contacto d-lg-block" src={bgCircle} alt="bgcircle" />
      </section>
  )
}
