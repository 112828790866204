import { useEffect, useState } from 'react'
import {useSelector} from 'react-redux'
import { getGroup_CustomTag } from '../../functions/functions_dev'
import zoom from '../../assets/images/signo_mas.svg';
import $ from 'jquery';
import "react-lightbox-pack/dist/index.css";
import { LightBox } from 'react-lightbox-pack'; // <--- Importing LightBox Pack
import SimpleReactLightbox ,{SRLWrapper} from 'simple-react-lightbox'
import { useLightbox } from 'simple-react-lightbox'
  
const Projects = () =>{
    const {data} = useSelector(store => store.developments.development)
    const settings = useSelector(store => store.settings)
    const { openLightbox, closeLightbox } = useLightbox()

	const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900
    },
    buttons: {
      iconColor: '#ffff'
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase'
    },
    progressBar: {
      height: '20px',
      fillColor: '#191919',
      backgroundColor: 'white'
    }
};

              

    
    return data ? (
        <div class="js-projects-gallery" >
            <section class="clients section col-12">
                <SRLWrapper>
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <div class="project image-grid-element">
                                <a className="cursor-pointer" title={data.photos[0].description} onClick={() => openLightbox(0)}>
                                    <figure className="h-100">
                                    {/* <div className="w-100 h-100 image-background-slider" alt="" style={{backgroundImage:"url(" + data.photos[0].original + ")"}} /> */}
                                    <img alt="" src={data.photos[0].original} />
                                        <figcaption>
                                        <div class={"project-zoom development-zoom"}><div className="zoom-image"  alt="" /></div>
                                            <h4 class="project-category development-category">
                                                {data.photos[0].description}
                                            </h4>
                                        </figcaption>
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <div class="project image-grid-element">
                                <a className="cursor-pointer" title={data.photos[1].description} onClick={() => openLightbox(1)}>
                                <figure>
                                    <img alt="" src={data.photos[1].original} className="w-100" />
                                    <figcaption>
                                    <div class={"project-zoom development-zoom"}><div className="zoom-image"  alt="" /></div>
                                        <h4 class="project-category development-category">
                                        {data.photos[1].description}
                                        </h4>
                                    </figcaption>
                                </figure>
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 d-flex flex-column justify-content-between mb-3 mb-lg-0 ">
                            <div class="project image-grid-element mb-3 mb-lg-4" >
                                <a className="cursor-pointer" title={data.photos[2].description} onClick={() => openLightbox(2)}>
                                <figure>
                                    <img alt="" className="w-100" src={data.photos[2].original} />

                                    <figcaption>
                                    <div class={"project-zoom development-zoom"}><div className="zoom-image"  alt="" /></div>
                                        <h4 class="project-category development-category">
                                        {data.photos[2].description}
                                        </h4>
                                    </figcaption>
                                    </figure>
                                </a>
                            </div>
                            <div class="project image-grid-element mb-5 mb-lg-0 pb-lg-0 mb-3 mb-lg-0">
                                <a className="cursor-pointer"  title={data.photos[3].description } onClick={() => openLightbox(3)} >
                                <figure>
                                    <img alt="" className="w-100" src={data.photos[3].original} />
                                    <figcaption>
                                    <div class={"project-zoom development-zoom"}><div className="zoom-image"  alt="" /></div>
                                        <h4 class="project-category development-category">
                                        {data.photos[3].description} 
                                        </h4>
                                    </figcaption>
                                    </figure>
                                </a>
                            </div>
                        </div>
                    {/* <LightBox
                        state={toggle}
                        event={lightBoxHandler}
                        data={data.photos}
                        imageWidth="60vw"
                        imageHeight="70vh"
                        thumbnailHeight={50}
                        thumbnailWidth={50}
                        setImageIndex={setSIndex}
                        imageIndex={sIndex}
                    /> */}
                    </div>
                    {data.photos.map((photo, index) => (
                        index > 3 ?
                        <img className='d-none' src={photo.original} alt="" />
                        :''
                    ))}
                </SRLWrapper>
            </section>
        </div>
    ): 
        <>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        <div class="project image-grid-element"></div>
        </>
}

export default Projects