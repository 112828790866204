import React from 'react'

// import { Link} from "react-router-dom";
// import { useEffect, useState } from "react";
// import { useSelector,useDispatch } from "react-redux";
// import { changeLocationAction } from "../../redux/themeDucks";
// import { updateFields,formTokko } from '../../redux/contactDucks'
// import ReCAPTCHA from "react-google-recaptcha";
// import { useLocation, useHistory } from "react-router-dom";
// import $ from 'jquery'
import {SectionForm} from '../section-form/SectionForm'
export const Footer = () => {
  function getYear() {
    const data = new Date;
    return(data.getFullYear())
  }
  const currentYear = new Date().getFullYear();
  return (
    <>
    <SectionForm />
    <footer className="footer d-lg-flex justify-content-center p-4 ">
      <div className="col-12 col-lg-4">
        <ul className="d-flex flex-column align-items-center flex-lg-row justify-content-center list-unstyled text-center">
          <li className="footer__text my-lg-2 mt-1 mb-5">
            Flamma Real Estate Partners
          </li>
        </ul>
      </div>
      <div className="col-12 col-lg-4 footer__text my-lg-2 my-1">
          <div className="copy mt-auto d-flex">
            <p> Design by {" "}<a className="mediahaus-link"  target="_blank" href="https://mediahaus.com.ar/">{" "}MediaHaus{" "} </a>{" "} - Powered By <a className="mediacore-link"  href="https://mediacore.app">MediaCore{" "}</a>
              < br /> 
              <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span>
            </p>
          </div>
      </div>
      <div className="col-12 col-lg-4">

      </div>
    </footer>
    </>
  )
}
