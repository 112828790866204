import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import {getDevelopmentsFilterCustomTag} from '../../functions/functions_dev'

import {updateFiltersDevelopment, getDevelopmentsAction} from '../../redux/developmentsDucks'
import { ButtonAnimationGray } from '../buttons/btnWhitRadius/ButtonAnimation'
import zoom from '../../assets/images/zoom.png';
import Card from '../development-card'

const Lotes = () =>{
    const dispatch = useDispatch();
    const {data} = useSelector(store => store.developments.developments);
    const loading = useSelector(store => store.developments.loading );
    const settings  = useSelector((store) => store.settings);
    const codes = useSelector((store) => store.developments.stateCodes);
    const filtersDevelopment = useSelector((store) => store.developments.filtersDevelopment).status;

    useEffect(()=>{
        console.log(filtersDevelopment)
    },[filtersDevelopment])

    return  (
        <section className="projects">
            <div className="blog-tags d-lg-flex flex-lg-row justify-content-xl-center align-items-baseline">
            <a onClick={() => dispatch(updateFiltersDevelopment(''))} className={"btn-filters-blog " + (!filtersDevelopment ? ' active ' : '')}>
                <ButtonAnimationGray text="Todos"></ButtonAnimationGray>
            </a>
            <a onClick={() => dispatch(updateFiltersDevelopment(3))} className={"btn-filters-blog" + (filtersDevelopment === 3 ? ' active ' : '')}>
                <ButtonAnimationGray text="En Pozo"></ButtonAnimationGray>
            </a>
            <a onClick={() => dispatch(updateFiltersDevelopment(4))} className={"btn-filters-blog" + (filtersDevelopment === 4 ? ' active ' : '')}>
                <ButtonAnimationGray style={{width: '25rem'}} text="En construcción"></ButtonAnimationGray>
            </a>
            <a onClick={() => dispatch(updateFiltersDevelopment(6))} className={"btn-filters-blog" + (filtersDevelopment === 6 ? ' active ' : '')}>
                <ButtonAnimationGray text="Terminado"></ButtonAnimationGray>
            </a>
            </div>
        <div>
            <div className="row m-auto justify-content-start">
                {
                    !loading ? //Cargó?
                        data.meta.total_count > 0 ? // Hay resultados?
                            getDevelopmentsFilterCustomTag(data.objects,"render","only-farias",true).sort((a,b)=>b.id-a.id).map((element,index) => (
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-4" key={index}>
                                    <Card element={element}/>
                                </div>
                            )) 
                            : //No hay resultados
                            <div className="mt-5">
                                <h4 className="text-center mt-5">No hay resultados</h4>
                            </div>
                        : //Están cargando los desarrollos
                        <>
                            <div class="loader relative">
                                <div class="page-lines">
                                    <div class="container">
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                    </div>
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                    </div>
                                    <div class="col-line col-xs-4">
                                        <div class="line"></div>
                                        <div class="line"></div>
                                    </div>
                                    </div>
                                </div>
                                <div class="loader-brand">
                                    <div class="sk-folding-cube">
                                    <div class="sk-cube1 sk-cube"></div>
                                    <div class="sk-cube2 sk-cube"></div>
                                    <div class="sk-cube4 sk-cube"></div>
                                    <div class="sk-cube3 sk-cube"></div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    </section>
    )
    
}

export default Lotes