import Breadcrumbs from "../../breadcrumbs"
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../../redux/MediaBlog/noveltiesDucks";

const Main = () => {

    const dispatch = useDispatch();
    const settings = useSelector(store => store.settings)
    const {tags} = useSelector(store => store.novelties)
    const [listActives,setListActive] = useState([])
    const {data} = useSelector((store) => store.novelties.grid_novelties);

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

      // Update filters in redux
        useEffect(() => {
            // if(activeTags.length > 0){
            dispatch(updateFiltersNovelties(listActives));
            // }
        }, [listActives])

        useEffect(() => {
            dispatch(clearNoveltyAction());
        } ,[])

    return (
        <main class="main " >
            <div class="px-4 px-lg-0">
                <div className="div_breadcrums">
                    <Breadcrumbs   
                    props={[
                        { name: "Home", route: settings.base_url, location: "" },
                        { name: "Novedades", route:  settings.base_url + "novedades", location: "", active:true },
                    ]}
                    />
                </div>
               <div className="container" style={{maxWidth:"100%"}}>
                <div className="hilera">
                        <div className="div_selected row">
                            <div className="div_search d-flex justify-content-between col-lg-8 col-10">
                                <div className="row div_first_search align-items-center">
                                    <div className="col-12 d-flex align-items-center">
                                        <i className="icon-tag fs-40 me-lg-5 me-3 mt-2 mt-lg-0"></i>
                                        <div className="d-flex justify-content-lg-start justify-content-center text-center text-lg-start flex-wrap">
                                            {listActives.map((element, index) =>(
                                                <div className="d-flex pill my-1 wow fadeInRight d-block" key={index}>
                                                    {tags.find(item => item.id === element).name}
                                                    <button className="d-flex align-items-center" onClick={() => toggleTag(element)}><i className="icon-delete ms-2"></i></button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className=" div_second_search d-flex align-items-center">
                                    <span className="fs-18 opacity-50 montserrat-regular me-3 d-none d-lg-inline-block cursor-pointer" onClick={() => setListActive([])}>Limpiar</span>
                                </div>
                                

                            </div>
                            <button onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}  className="btn-search montserrat-bold col-2 text-uppercase align-items-center justify-content-center d-flex"> <div className="d-none d-lg-inline-block"> Buscar </div> <i className="d-block d-lg-none icon-search"></i></button>

                            
                        </div>
                </div>
                <div className="d-flex flex-wrap mt-5">
                    {tags.map((element,index) => (
                        listActives.find(e => e === element.id) ? '' :
                        <div className="pill cursor-pointer  mb-3 ">
                            <div key={index} onClick={() => toggleTag(element.id)} className="">{element.name}</div>
                        </div>
                    ))}
                </div>
               </div>
            </div>
            

        </main>
    )
}

export default Main