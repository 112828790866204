import React , {useEffect} from 'react'

// -- Componentes -- //
// import Main from '../components/contacto/main'
// import Details from '../components/contacto/details'
// import Objects from '../components/contacto/objects'
// import Contact from '../components/form'
import { Contacto } from '../components/pages/contacto/Contacto'
import { useDispatch } from 'react-redux'
import { cleanDevelopmentAction } from '../redux/developmentsDucks';
// import { getDevelopmentsAction } from '../redux/developmentsDucks';
import { clearPropertyAction } from '../redux/propertiesDucks'
import { getActionsAction } from '../redux/actionsDucks'



function ScrollToTopOnMount() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(cleanDevelopmentAction())
      // dispatch(getDevelopmentsAction())
      dispatch(clearPropertyAction())
      dispatch(getActionsAction())
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function PageContacto(){

    ScrollToTopOnMount()
    return(
      <Contacto />
    )
}

export default PageContacto;
