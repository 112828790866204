import React, { useEffect } from 'react';

// -- Componentes -- //
import Main from '../components/novedad/main';
import { FirstSection } from '../components/novedad/FirstSection.jsx';
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { Whatsapp } from '../components/Whatsapp'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {getNoveltieAction,getNoveltiesAction,clearNoveltyAction} from '../redux/MediaBlog/noveltiesDucks';


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Novedad(){

    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(clearNoveltyAction())
        dispatch(getNoveltieAction(id));
        dispatch(getNoveltiesAction())
      }, []);

    ScrollToTopOnMount()
    return(
        <div className="novedad">
          <Header />
            <div className="d-block d-lg-flex">
              <div className=''>
               <Sidebar/>
              </div>
              <div className="">
                <Whatsapp />
                <FirstSection  />
                <Main />
              </div>
            </div>
        </div>
    )
}

export default Novedad
