import React, {useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import {getGroup_CustomTag} from '../../functions/functions_dev'
import { useDispatch } from 'react-redux';
import {getNoveltiesAction} from '../../redux/MediaBlog/noveltiesDucks'



export const Navbar = () => {

  const dispatch = useDispatch()

  const settings = useSelector((store) => store.settings);
  const {data} = useSelector((store) => store.developments.development);
  const novelties = useSelector((store) => store.novelties.novelties);
  const unidad = useSelector((store) => store.properties.property).data;

  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''

  useEffect(() => {
    dispatch(getNoveltiesAction())
    // if(!novelties){
    // }
  },[])

  return  (
    <div className="w-100 navbar justify-content-end py-5 d-md-flex col-12 m-auto">
      {/* {console.log(novelties)} */}
      <div className="container">
      <nav className="col-12 d-flex justify-content-end m-auto">
          <Link>
            <img  id="d-only" style={{maxWidth:"15rem"}} className={view === "desarrollo" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" />
            <img id="d-only"  className={view === "unidad" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( unidad ? getGroup_CustomTag(unidad.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" />
          </Link>
          <ul className="nav">
            {
              view !== "desarrollo" && view !== "unidad" && view!== "modulo" ? ( // Validador de si es o no una vista
                <>
                  <li className="nav-item">
                    <Link className={"nav-link sspro-regular fs-14 " + (curLocation === '' ? "active" : '')} to={settings.base_url }>Home</Link>
                  </li>
                  {/* <li className={(pathname === '' ? 'active ' : '')}><Link onClick={() => staticBody()} className="" to="/">HOME <span>01</span></Link></li> */}
                  <li className="nav-item">
                    <Link className={"nav-link sspro-regular fs-14 " + (curLocation === 'desarrollos' ? "active" : '')} to={ settings.base_url + "desarrollos" }>Desarrollos</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link sspro-regular fs-14 " + (curLocation === 'manifiesto' ? "active" : '')} to={ settings.base_url + "manifiesto" }>Manifiesto</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link sspro-regular fs-14 " + (curLocation === 'nosotros' ? "active" : '')} to={ settings.base_url + "nosotros" }>Nosotros</Link>
                  </li>
                  { novelties?.length > 0 ?
                      <li className="nav-item">
                        <Link className={"nav-link sspro-regular fs-14 " + (curLocation === 'novedades' ? "active" : '')} to={ settings.base_url + "novedades" }>Novedades</Link>
                      </li> : null }
                </>
              ): // Validar scrolls
                <> 
                  <li className="nav-item">
                    <Link className="nav-link active sspro-regular fs-14" to={ settings.base_url }>Proyecto</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sspro-regular fs-14" to={ settings.base_url}>Unidades</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sspro-regular fs-14" to={ settings.base_url}>Ubicación</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sspro-regular fs-14" to={ settings.base_url}>Nosotros</Link>
                  </li>
                </>
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}
