import React from 'react'
import { FirstSection } from './FirstSection'
import { Header } from '../../header/Header';
import { Whatsapp } from '../../Whatsapp'

export const Main = () => {
  return (
    <>
      <Header />
      <Whatsapp />
      <FirstSection />
    </>
  )
}
