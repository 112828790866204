
import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import zoom from '../assets/images/signo_mas.svg';

const dev_Card = (props) => {
    
    // const settings  = useSelector((store) => store.settings);

    function get_cover(photos){
        return photos.find(element => element.is_front_cover) ? photos.find(element => element.is_front_cover) : photos[0] ? photos[0] : 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1';
    }

    const {element} = props;

    return props.element ? (
        <div class="card__project">
            <div className={"vendido " + (element.custom_tags.find(item => item.name === "100vendido") ? ' d-block ' : ' d-none ')}>100% VENDIDO</div>
            {element.construction_status !== 2 && element.construction_status !== 1 ?
                <Link to={"desarrollo/" + element.id + "/" + ( element ? (element.name).replace(/ /g,"-").toLowerCase() : '')} title={element.publication_title} >
                <div class="card__project__container">
                    <div class="card__project__bg">
                    <img src={ get_cover(element.photos).original } alt="" />
                    {/* {
                        element.custom_tags.find(e => e.name === '100vendido') ?
                        <img src={settings.base_url + "img/vendido.png"} alt="" className="position-absolute absolute-top-right" />
                        :
                        ''
                    } */}
                    <div class="card__title">
                        { element.name }
                    </div>
                    <div class="card__project__data">
                        <div class="card__data__image">
                            <img src={zoom} alt="" />
                        </div>
                        <div class="card__data__text mt-lg-4">
                            <p className="mb-0">{element.address}</p>
                            <p>{element.location.name}</p>
                        </div>
                    </div>
                </div>
            </div>
            </Link>

            :''
            }
        </div>
    ): ''
} 

export default dev_Card