import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom";
import Card_news from "../Global/card_new";

import {getThree} from '../../../functions/MediaBlog/functions_novelties'

import {getNoveltiesAction} from '../../../redux/MediaBlog/noveltiesDucks'

function News() {
  const dispatch = useDispatch();
  
  const novelties = useSelector(store => store.novelties.novelties)

    useEffect(() => {
        dispatch(getNoveltiesAction(true))
    },[])

  return (
    <>
        <section className={"div_news position-relative " + (novelties?.length === 0 ? 'd-none' : '')}>
            <div className="container-fluid">
                <div className="div_news_container">
                    
                        <h2 className="section-title ">Nuestras novedades</h2>
                       
                        <div className="hilera row">
                            {novelties ? 
                                getThree(novelties).map((item, index) => (
                                    <div key={index} className=" mb-5 mb-lg-0 col-12 col-lg-4"  >
                                        <Card_news tags_count={2} isHome={true} novelty={item} />
                                    </div>
                                )):''
                            }
                        </div>
                        <div className="div_button d-flex justify-content-center col-12 ">
                            <Link className="buttonGhostTwo d-flex align-items-center text-uppercase font-1" to="novedades">
                                <span className="fs-7 text-uppercase font-1">VER TODAS LAS NOVEDADES</span>
                            </Link>
                        </div>
                </div>
            </div>
        </section>
    </>

  )
}

export default News;
