// import blog1 from "../../assets/images/blog-1.jpg"

import Card_news from "../Global/card_new"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../../redux/MediaBlog/noveltiesDucks";
import circlePrimary from '../../../assets/images/elipse_bg_home_primary.svg';



const Blogs = () => {
    const dispatch = useDispatch()
    
    const settings = useSelector((store) => store.settings);
    const data = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    
    useEffect(() => {
        dispatch(getFiltersNoveltiesAction())
    }, [])

    return (
            <section className="div_blog position-relative d-block">
                    <div className="container " style={{maxWidth:"100%"}}>
                        {/* <div className="div_blog__title  d-none d-lg-flex mb-5">
                            <h2 className="">Conoce las ultimas novedades</h2>
                        </div> */}
                        <div className="div_blog_container">
                            {/* <div className="div_vertical">
                                <p className="fs-7 font-0 text-type-1">Nuestras novedades</p>
                            </div> */}
                            {/* <img className="circlePrimary d-block" src={circlePrimary} alt="svgCirclePrimary" /> */}
                            <div className="row div_cards-news">
                                {data ? 
                                    data.length > 0 ?
                                        data.sort((a,b)=>b.position-a.position).map((item, index) => (
                                            item.is_active.toString() === 'true' ||item.active.toString() === '1' ?
                                                <div className="mb-5 col-lg-10 px-lg-3 px-0"  key={index}>
                                                    <Card_news novelty={item} />
                                                </div> : ''
                                            )):
                                                <h4 style={{fontSize:"1rem"}}>
                                                    No existen novedades relacionadas con el filtro de búsqueda seleccionado
                                                </h4>
                                    :
                                        <>
                                            <div class="loader relative">
                                                <div class="page-lines">
                                                    <div class="container">
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="loader-brand">
                                                    <div class="sk-folding-cube">
                                                    <div class="sk-cube1 sk-cube"></div>
                                                    <div class="sk-cube2 sk-cube"></div>
                                                    <div class="sk-cube4 sk-cube"></div>
                                                    <div class="sk-cube3 sk-cube"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                {/* <div className="col-12 d-flex w-100 align-items-center justify-content-center mt-5 mb-5">
                                    <button className="buttonGhostTwo text-uppercase font-1 align-items-center justify-content-start d-flex" >
                                        <span className="fs-7 text-uppercase font-1">Ver más</span>
                                        
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                </section>
           
    
    
      )
}

export default Blogs