import React from 'react'
import { useSelector } from 'react-redux'

import bgDev from '../../../assets/images/mmcv_nuevalynch_fotomontaje3_a001.png'
import BannerTOP from '../../Global/Modules/MediaPush/BannerTop';

// Custom Hook
import useWindowSize from '../../../hooks/useWindowSize';

export const FirstSection = () => {

  // Hook windwo size
  const windowSize = useWindowSize();
  // Page
  const page = 'emprendimientos';

  // Get actions
  const actions = useSelector((store) => store.actions);

  // Check actions in home and convert in bool
  const actionsInPage = actions.actions?.filter(action => action.section.toLowerCase() === page)[0];
  const hasActionsPage = (actionsInPage) 
      ? Boolean(actionsInPage.section.toLowerCase() === page) 
      : undefined;

  // Filter slider actions and convert in bool
  const sliderAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'slider de imagenes encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasSlider = (sliderAction) 
      ? Boolean(sliderAction.type.toLowerCase() === 'slider de imagenes encabezado') 
      : undefined;
    
  // Filter image actions and convert in bool
  const imageAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'imagen encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasImage = (imageAction)
      ? Boolean(imageAction.type.toLowerCase() === 'imagen encabezado')
      : undefined;
    
  // Filter video actions and convert in bool
  const videoAction = actions.actions?.filter(action => (
      action.type.toLowerCase() === 'video encabezado'
      && action.section.toLowerCase() === page))[0];
  const hasVideo = (videoAction)
      ? Boolean(videoAction.type.toLowerCase() === 'video encabezado')
      : undefined;

  // Conditional Render to show sections
  const conditionalRedner = () => {
      // has actions in home?
      if (hasActionsPage) {
          // has video?
          if (hasVideo) {
              // has window size > 992
              return (windowSize.width > 992)
                  ? <BannerTOP page={page} actions={actions.actions} />
                  : staticContent();
          // has slider?
          } else if (hasSlider) {
              return <BannerTOP page={page} actions={actions.actions} />
          // has image?
          }else if (hasImage) {
              return <BannerTOP page={page} actions={actions.actions} />
          } else {
              return staticContent();
          }
      } else {
          return staticContent();
      }
  }

  const staticContent = () => {
    return <img className="main__first__section__background__desarrollo" src={ bgDev } alt="Emprendimientos" />
  }

  return (
    <section className="main__first__section__desarrollo d-flex" id="firstSection">
      <div className="main__first__section__container__desarrollo">
        { conditionalRedner() }
        { (!hasSlider || hasImage || hasVideo) ?
          <div className="main__first__section__title__desarrollo d-flex align-items-end justify-content-end">
            <div className="main__first__section__title__container__desarrollo m-auto col-10 h-100 d-flex flex-column justify-content-center">
              <h1 className="text-uppercase fs-60 montserrat-bold text-end mb-5" style={{ letterSpacing: '.2rem' }}>Nuestros desarrollos</h1>
              <p className="montserrat-medium text-end fs-23">
                Una obra de arquitectura que funde diseño, arte y tecnología con un estilo único.
                <br /> Un proyecto que interpreta la ciudad con un respeto absoluto por su entorno.
                <br /> La síntesis perfecta entre pasado, presente y futuro.
              </p>
            </div>
          </div> : null }
      </div>
    </section>
  )
}
