import React from 'react';
import { Main } from './main/Main';
import { Whatsapp } from '../../Whatsapp';

export const Nosotros = () => {
  return (
    <>
      <Whatsapp />
      <Main />
    </>
  )
}
