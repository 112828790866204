import React, {useRef,useEffect} from "react";
import {useSelector} from 'react-redux'
import {get_cover, get_shortDescription } from "../../functions/functions_dev"
import { Link } from 'react-router-dom'
import { Header } from './Header' 
import OwlCarousel from 'react-owl-carousel3';

const Main = () =>{
    const {data} = useSelector(store => store.developments.development)
    
    const slider = useRef()
    

    const getDescription = (element) => {
        if(element.id ===39383){
            return element.description.split(".")[0] + ".";
        }
        else{
            return get_shortDescription(element.description).title
        }
    }

  
    useEffect(() => {
      window.scrollTo(0, 0);
    });

    return data ? (
        <>
            <Header />
            <main class="main">
                <OwlCarousel
                    loop={true}
                    // dots={true}
                    autoplay={true}
                    center={true}
                    items={1}
                    margin={0} 
                    rewind={true}
                    drag={false}
                    ref={slider}>
                    {data.photos.map((element,index) => (
                        <div className="">
                            <img src={(element.original)} alt="" />
                            <div className="opacity"></div>
                        </div>
                    ))}
                </OwlCarousel>
                <div className="art">
                    <div className="container">
                        <div className="text-content position-relative d-flex justify-content-center align-items-end flex-column">
                            <h2>{data.name}</h2>
                            <h4 className="sspro-regular">{data.publication_title}</h4>
                            <p>{getDescription(data)}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="arrow__container d-flex justify-content-between">
                    <div onClick={() => (slider.current.prev())} className="icon-arrow_left arrow_left"></div>
                    <div onClick={() => (slider.current.next())} className="icon-arrow_right arrow_right"></div>
                </div>
                <div className="arrow-left"></div>
                <div className="arrow-right"></div> */}
            </main>
        </>
    ):
    <>
        
    </>
}

export default Main