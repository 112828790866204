import React from 'react'
import { Main } from './main/Main'
import { Whatsapp } from '../../Whatsapp'

export const Contacto = () => {
  return (
    <>
      <Whatsapp />
      <Main />
    </>
  )
}
