import React from 'react'
import edificioNosotros from '../../../../assets/images/edificio_nosotros.png' 
import bgCircle from '../../../../assets/images/elipse_bg_contact.svg';
import BreadCrumbs from '../../../breadcrumbs';
import {Link} from 'react-router-dom'
import { ButtonAnimation } from "../../../buttons/btnWhitRadius/ButtonAnimation";

export const SecondSection = () => {
  return (
    <section className="main__second__section">
      <div className="main__second__section__container col-lg-12 col-10 m-auto">
        <div className="d-flex flex-xl-row flex-column justify-content-center align-items-center">
          <div className="col-12 col-xl-6 d-flex flex-column">
            <BreadCrumbs
                props={[
                  { name: "Home", route: "/", location: "home" },
                  { name: "Manifiesto", active:true },
                ]}
              /> 
            <div className="main__description__container">
              <p className="main__description__title sspro-light pt-5 mb-5 pb-lg-5 d-block mt-4">
                Nos apasiona desarrollar proyectos que eleven la calidad de vida de las personas, esa es la energía que nos activa, moviliza y nos lleva a transformar cada emprendimiento en <div style={{fontWeight:"bold",display:"inline-block"}}> espacios únicos, diferenciados y del primer nivel. </div> 
                <br />
                <br />
                Nuestra prioridad es brindar la mejor experiencia a cada cliente a través de una gestión integral que abarca todas las instancias del desarrollo de un proyecto.
                <br />
                <br />
                Contamos con una extensa experiencia en desarrollos inmobiliarios que nos permite detectar rápidamente las nuevas oportunidades, avanzar de forma ágil, con flexibilidad e incorporar las <div style={{fontWeight:"bold",display:"inline-block"}}> últimas tendencias del sector. </div>
                <br />
                <br />
                Diseñamos proyectos que acompañan la forma de vida de cada persona, con estilos únicos y propuestas exclusivas.
                <br />
                <br />
                <b style={{fontWeight:"800"}}>Flamma Real Estate Partners.</b>
                
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
              <br />
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore. */}
              </p>
              <Link to="nosotros" >
                  <ButtonAnimation text="Conocenos" style={{ marginTop: '48px' }} />
                </Link>
            </div>
          </div>
          <div className="col-12 col-xl-6 d-flex justify-content-xl-end justify-content-center">
            <img className="main__second__section__image d-md-flex" src={ edificioNosotros } alt="" />
          </div>
        </div>
      </div>
      <img className="main__description__bg__circle" src={ bgCircle } alt="asd" />
    </section>
  )
}
