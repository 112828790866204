import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

export const MenuMobile = ({ isClicked }) => {
  const settings = useSelector((store) => store.settings);
  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''
  const novelties = useSelector((store) => store.novelties.novelties);

  useEffect(() => {
    // console.log(curLocation)
  },[curLocation])

  const staticBody = () => {
    var body = document.body;
    body.classList.toggle("overflow-hidden-mobile");
  }

  return (
    <div className={`header__menu__mobile d-lg-none ${ isClicked ? 'active' : '' }`}>
      <div className="header__menu__container h-100 d-flex justify-content-center">
        <ul className="d-flex flex-column align-items-center justify-content-center p-0">
          <li onClick={() => staticBody()} className="montserrat-semibold fs-18 mt-5">
            <Link to="/" className={"text-primary-orange " + (curLocation === '' ? "active" : '')}>Inicio</Link> 
          </li>
          <li onClick={() => staticBody()}  className="montserrat-semibold fs-18 mt-5">
            <Link to="/desarrollos" className={"text-primary-orange " + (curLocation === 'desarrollos' ? "active" : '')}>Desarrollos</Link> 
          </li>
          <li onClick={() => staticBody()}  className="montserrat-semibold fs-18 mt-5">
            <Link to="/manifiesto" className={"text-primary-orange " + (curLocation === 'manifiesto' ? "active" : '')}>Manifiesto</Link>
          </li>
          <li onClick={() => staticBody()}  className="montserrat-semibold fs-18 mt-5">
            <Link to="/nosotros" className={"text-primary-orange " + (curLocation === 'nosotros' ? "active" : '')}>Nosotros</Link>
          </li>
          <li onClick={() => staticBody()}  className={"montserrat-semibold fs-18 mt-5" + (novelties?.length === 0 ? ' d-none ' : '')} >
            <Link to="/novedades" className={"text-primary-orange " + (curLocation === 'novedades' ? "active" : '')}>Novedades</Link> 
          </li>
        </ul>
      </div>
    </div>
  )
}
