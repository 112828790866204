import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import useWindowSize from "../../../../hooks/useWindowSize"
import { informCustomEvent } from "../../../../helpers/helper.analytics"

// const SquarePopup = ({align, flat, linkTo, showOn, image, square = false}) => {
const SquarePopup = ({align, flat, linkTo, showOn, image,square = false, utmValue,small_type,small_section,name}) => {

    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')
    const windowSize = useWindowSize();

    const isFlatMobile = () => {
        return Boolean(flat && windowSize.width > 992)
    }

    // Timeout to show the component
    useEffect(() => {
        if(checkPage()) {
            setTimeout(() => {
                document.getElementById("squarePopUpBtn")?.click()
            }, 1000)
        }
    }, [pathname])

    // Check align
    const checkAlign = () => {
        return (align.toLowerCase() === 'left') ? 'align-left' 
             : ((align.toLowerCase() === 'right') ? 'align-right' 
                : ((align.toLowerCase() === 'center') ? 'align-center' : ''))
    }

    // Limit the height
    const checkFlat = () => {
        return (flat) ? 'flat-width' : ''
    }

    // Go to link, disable popup
    const goTo = () => {
        document.getElementById("closePopup").click()
        if (linkTo) {
            informCustomEvent("PUSH_" + small_section + "_" + small_type + "_" + name)
            window.open(linkTo, '_blank', 'noopener,noreferrer')
        }
    }

    // Check where shows
    const checkPage = () => {
        if(pathname === '' && showOn.toLowerCase() === 'inicio'){return true}
        if(pathname.toLowerCase().slice(0,6).includes('unidad') && showOn.toLowerCase() === 'ficha de la propiedad'){return true}
        return (Array.isArray(showOn)) 
            ?  showOn.toLowerCase().includes(pathname.toLowerCase())
            : ( (pathname.toLowerCase() === showOn.toLowerCase()) || false )
    }

    const popUpComponent = () => {
        return <div className="article square-popup">
                    <button type="button" id="squarePopUpBtn" data-bs-toggle="modal" data-bs-target="#squarePopUp"></button>
                    <div className="modal"  id="squarePopUp" tabIndex="-1" aria-hidden="true">
                        <div className={`modal-dialog ${checkAlign()} ${checkFlat()}  ${square ? 'square' : ''}`}>
                            <div className={`modal-content ${checkFlat()} ${square ? 'square' : ''}`} style={{backgroundImage: 'url('+ image + ')'}}>
                                <div className="modal-header" onClick={() => document.getElementById("closePopup").click()}>
                                    <button className="btn btn-link btnClose" type="button" role="button"
                                        id="closePopup" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => document.getElementById("closePopup").click()}>
                                        &times;
                                    </button>
                                </div>
                                <div className={`modal-body ${checkFlat()} ${square ? 'square' : ''}`} onClick={() => goTo() }></div>
                            </div>
                        </div>
                    </div>
                </div>
    }

    return checkPage() ? (
        <>
            { isFlatMobile() ? popUpComponent() : null }
            { !flat ? popUpComponent() : null }
        </>
    ) : null
}

export default SquarePopup

// Component usage
// <SquarePopup -> Square aligned center by default
//      align={'right' || 'left' || 'center'} -> Set the align for vertical banner
//      flat={true} -> Square flat with max-height
//      linkTo={'/emprendimientos/'} -> Set the link of image
//      showOn={'servicios' | ['venta','alquiler'] } /> -> Set route where shows the banner
