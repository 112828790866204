import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag} from '../../functions/functions_dev'
import BreadCrumbs from "../breadcrumbs";

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);


    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        empresa:'',
        phone:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      // dispatch(formTokko('Contacto',null))
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
  
  // Render
  return data ? (
    <section class={"section " + ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')} id="proyectos">
      <div class="container ">
        <div style={{marginLeft:"1em"}}>
              <BreadCrumbs
                props={[
                  { name: "Home", route: settings.base_url, location: "" },
                  { name: "Desarrollos", route:  settings.base_url + "desarrollos", location: "" },
                  {
                    name: data.name,
                    route:'',
                    active:true
                  }
                ]}
              />
          </div>

          <div class="lading-project-about">
              <div class="" style={{margin: "0 1em"}}>
                  <h3 class="montserrat-semibold fs-30 proyect-color">“{data.publication_title}”</h3>
                  <p style={{marginTop: "1.5em;"}} className="montserrat-light fs-24">{get_shortDescription(data.description).description}</p>
                  {
                    (data.files.length > 0 ?
                    <a target="_blank" href={data.files[data.files.length - 1].file} style={{display:"flex",alignItems:"center"}} className={"btn " +( data ? 'bg-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')}><i style={{fontSize: "1.1rem",marginRight:".5rem"}} className="icon-download"></i> Descargar presentación</a>
                    :''
                  )}
              </div>
              <div className="m-0 ms-lg-5" >
                  <h3 class="mb-lg-5 mt-5 mt-lg-0 proyect-color">Características:</h3>
                  <div className="amenities-mobile" style={{margintTop: "3.5em"}}>
                      <div className="item-amenities">
                        <i className={"icon-finaciacion proyect-color"}></i>
                        <h4 className="ms-3">FINANCIACIÓN</h4>
                      </div>
                      <div className="item-amenities">
                        <i className={"icon-conectividad proyect-color"}></i>
                        <h4 className="ms-3">CONECTIVIDAD</h4>
                      </div>
                      <div className="item-amenities">
                        <i className={"icon-premium proyect-color"}></i>
                        <h4 className="ms-3">CONSTRUCCIÓN PREMIUM</h4>
                      </div>
                      <div className="item-amenities">
                        <i className={"icon-amenities proyect-color"}></i>
                        <h4 className="ms-3">AMENITIES</h4>
                      </div>
                  </div>
              </div>
              <div class="text-center" id="d-only" style={{}}>
                  <img alt=" " src={settings.base_url + "img/logos/"+ ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')+ "_vertical" +".svg"} width="125px" />
              </div>
          </div>
      </div>
</section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
