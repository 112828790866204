import { data } from 'jquery'
import { useSelector } from 'react-redux';
import Map from '../map'
const Location = () => {
    const {data} = useSelector((store) => store.developments.development);

    return data ? (
        <>
            <section style={{marginTop: "5em",textAlign: "center"}} id="ubicacion">
                <div style={{textAlign: "center"}}>
                    <h2 className="fs-50 montserrat-bold" style={{ letterSpacing: ".2rem" }}>Ubicación</h2>
                    <h5 className="montserrat-light fs-30" style={{marginTop:"1.5rem", color: "#C1BCBC"}}>{data.fake_address ? (data.fake_address +  ", " + data.location.short_location.split(" | ")[1]) + "." : ''}</h5>
                </div>
                <div className="dev-map">
                    <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />
                </div>
            </section>
        </>
    )
    :
    ''

}

export default Location