import React from 'react'

export const ButtonAnimation = ({ disabled ,type, text, className = null }) => {
  return (
    <button disabled={ disabled } type={ type } className={"buttonAnimation text-uppercase montserrat-bold fs-20 " + className}>
      { text }
    </button>
  )
}
export const ButtonAnimationGray = ({ type, text }) => {
  return (
    <button type={ type } className="buttonAnimation buttonAnimationGray text-uppercase montserrat-bold fs-20">
      { text }
    </button>
  )
}
