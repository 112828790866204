import { useSelector } from "react-redux";
import {get_shortDescription, getGroup_CustomTag} from "../../functions/functions_dev"
import imageDetails from '../../assets/images/edificio_nosotros.png'
import dblCircle from '../../assets/images/circles_bg_aera.svg';
import imageDetails2 from '../../assets/images/slide-3-desk3.png';
import circleBg from '../../assets/images/elipse_bg_emprendimiento.svg';

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

  
  // Render
  return data ? (
    <>
      <section className="section__details__emprendimiento position-relative">              
        <div className={"section__details__container col-10 m-auto d-flex justify-content-center align-items-center " +  (get_shortDescription(data.description).photos[0].length > 30 ? 'd-block' : "d-none")}>
          <div className="section__1 section__image__container position-relative d-flex justify-content-center align-items-center">
            <img src={data.photos[0] ? data.photos[0].original : ''} alt="" />
            <div className="section__details__text proyect-bg d-flex justify-content-center align-items-center">
              <p className="montserrat-medium fs-23">
                {get_shortDescription(data.description).photos[0]}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore. */}
              </p>
            </div>
          </div>
        </div>
        <img className="image__details__circles position-absolute d-lg-block" src={ dblCircle } alt="dblcircle" />
      </section>
      <section className="section__details__emprendimiento position-relative">              
        <div className={"section__details__container col-10 m-auto section__2 " +  (get_shortDescription(data.description).photos[1].length > 30 ? 'd-block' : "d-none")}>
          <div className="section__image__container2 position-relative" style={{ maxWidth: "1024px" }}>
            <img style={{ width: '1019px', height: '652px', objectFit:"cover"}} src={data.photos[1] ? data.photos[1].original : ''}  alt="" />
            <div className="section__details__text2 d-flex justify-content-center align-items-center">
              <p className="montserrat-medium fs-23">
                {get_shortDescription(data.description).photos[1]}
              </p>
            </div>
          </div>
        </div>
        <img className="image__details__bg position-absolute d-lg-block left-image-float" src={ circleBg } alt="dblcircle" />
      </section>
      <section className="section__details__emprendimiento position-relative ">              
        <div className={"section__details__container col-10 m-auto d-flex justify-content-center align-items-center section__3 " +  (get_shortDescription(data.description).photos[2].length > 30 ? 'd-block' : "d-none")}>
          <div className="section__image__container position-relative d-flex justify-content-center align-items-center">
            <img className="section__image__3" style={{borderRadius:"50%"}} src={data.photos[2] ? data.photos[2].original : ''}  alt="" />
            <div className="section__details__text3 proyect-bg d-flex justify-content-center align-items-center">
              <p className="montserrat-medium fs-23">
              {get_shortDescription(data.description).photos[2]}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;


