import React from 'react'

import bgDev from '../../../assets/images/novedad-main.jpg';
import Main from '../../../components/MediaBlog/Novedades/main';
import Blogs from '../../../components/MediaBlog/Novedades/blogs';
import circlePrimary from '../../../assets/images/elipse_bg_home_primary.svg';


export const FirstSection = () => {
  return (
    <section className="main__first__section__novedades d-flex" id="firstSection">
      <img className="circlePrimary d-block" src={circlePrimary} alt="svgCirclePrimary" />
      <div className="main__first__section__container__novedades">
        <img className="main__first__section__background__novedades" src={"https://static.tokkobroker.com/dev_original_pictures/87760871351727682973507265527166597261579887835320147052895879335081139280562.jpg"} alt="" />
        <div className="main__first__section__title__novedades main__first__section__title__desarrollo  d-flex justify-content-end">
          <div className="main__first__section__title__container__novedades   h-100 d-flex flex-column justify-content-end">
            <h1 className=" montserrat-bold fs-60" >Novedades</h1>
          </div>
        </div>
        <div className="layout">
          <div className="content">
            {/* <div className="container-fluid"> */}
              <div className="row">
                <div className="col-12">
                  <Main />
                  <Blogs />
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}
