import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { get_shortDescription } from "../../functions/functions_dev";
import OwlCarousel from 'react-owl-carousel3';
import Arrow from '../../assets/images/arrow_slider.svg'

import BannerTOP from "../Global/Modules/MediaPush/BannerTop";

// Custom Hook
import useWindowSize from "../../hooks/useWindowSize";

function Main() {

    const data_dev = useSelector((store) => store.developments.developments).data;
    const actions = useSelector((store) => store.actions);
    const slider = useRef()

    const getDescription = (element) => {
        if(element.id ===39383){
            return element.description.split(".")[0] + ".";
        }
        else{
            return get_shortDescription(element.description).title
        }
    }

    const getCover = (element) => {
        return element.filter(element => element.is_front_cover) ? element.filter(element => element.is_front_cover)[0].original : 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1'
    }

    // Hook window size
    const windowSize = useWindowSize();
    // Page
    const page = 'inicio';
    
    // Check actions in home and convert in bool
    const actionsInPage = actions.actions?.filter(action => action.section.toLowerCase() === page)[0];
    const hasActionsPage = (actionsInPage) 
        ? Boolean(actionsInPage.section.toLowerCase() === page) 
        : undefined;
    
    // Filter slider actions and convert in bool
    const sliderAction = actions.actions?.filter(action => (
        action.type.toLowerCase() === 'slider de imagenes encabezado'
        && action.section.toLowerCase() === page))[0];
    const hasSlider = (sliderAction) 
        ? Boolean(sliderAction.type.toLowerCase() === 'slider de imagenes encabezado') 
        : undefined;
     
    // Filter image actions and convert in bool
    const imageAction = actions.actions?.filter(action => (
        action.type.toLowerCase() === 'imagen encabezado'
        && action.section.toLowerCase() === page))[0];
    const hasImage = (imageAction)
        ? Boolean(imageAction.type.toLowerCase() === 'imagen encabezado')
        : undefined;
     
    // Filter video actions and convert in bool
    const videoAction = actions.actions?.filter(action => (
        action.type.toLowerCase() === 'video encabezado'
        && action.section.toLowerCase() === page))[0];
    const hasVideo = (videoAction)
        ? Boolean(videoAction.type.toLowerCase() === 'video encabezado')
        : undefined;

    // Conditional Render to show sections
    const conditionalRender = () => {
        // has actions in home?
        if (hasActionsPage) {
            // has video?
            if (hasVideo) {
                // has window size > 992
                return (windowSize.width > 992)
                    ? <BannerTOP page={page} actions={actions.actions} />
                    : staticContent();
            // has slider?
            } else if (hasSlider) {
                return <BannerTOP page={page} actions={actions.actions} />
            // has image?
            }else if (hasImage) {
                return <BannerTOP page={page} actions={actions.actions} />
            } else {
                return staticContent();
            }
        } else {
            return staticContent();
        }
    }

    const staticContent = () => {
        return <>
            <OwlCarousel
                loop={true}
                dots={false}
                center={true}
                items={1}
                margin={0} 
                autoplay={true}
                rewind={true}
                ref={slider}>
                {data_dev.objects.filter(element => element.is_starred_on_web).sort((a,b)=>b.id-a.id).map((element,index) => (
                    <div className="art">
                        <div className="container">
                            <img src={getCover(element.photos)} alt="" />
                            <div className="opacity"></div>
                            <div className="text-content position-relative d-flex justify-content-center align-items-end flex-column">
                                <h2>{element.name}</h2>
                                <h4 className="sspro-regular">{element.publication_title}</h4>
                                <p>{getDescription(element)}</p>
                                <Link to={"desarrollo/"+ element.id +"/" + element.name.replace(/ /g,"-").toLowerCase()}>CONOCÉ MÁS</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
            <div className="arrow__container d-flex justify-content-between">
                <div onClick={() => (slider.current.prev())} className="cursor-pointer"><img src={Arrow} alt="" /></div>
                <div style={{transform:"rotate(180deg)"}} onClick={() => (slider.current.next())} className="cursor-pointer ml-4"><img src={Arrow} alt="" /></div>
            </div>
        </>;
    }

    return data_dev ?(
        <main className="main d-lg-block"  style={{ width: "calc(100% - 250px)"}}>
            { conditionalRender() }
        </main>
  )
  :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main;
