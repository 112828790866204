import React from "react";
import {Link} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTextShort,getDateTime } from '../../../functions/MediaBlog/functions_novelties'
import { getNoveltieAction, clearNoveltyAction } from "../../../redux/MediaBlog/noveltiesDucks";

const Card_news = (props) => {
    
    const dispatch = useDispatch()
    const settings = useSelector((store) => store.settings);
    const {novelty} = props
    const tags_count = props.tags_count ? props.tags_count : 999
    const isHome = props.isHome ? props.isHome : false

    return (
        <div className={"div_card_new position-relative " + (isHome ? 'home-card' : '') } >
            <Link  onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))} className="see_more d-flex" to={settings.base_url + "novedades/" + novelty.id + "/" + novelty.slug}>
                <img className="position-absolute img_card" src={novelty.image} alt={novelty.title} />
            </Link>
            <div className="card_new p-lg-5 p-2" > 
                <div className="content_card">
                    <div className="content_body">
                        <span className="text-left card_date fs-22 sspro-light">{getDateTime(novelty.date_published)}</span>
                        <h3 className="text-left mt-3 fs-22 montserrat-bold">{getTextShort(novelty.title,(isHome ? 40 : 80))}</h3>
                        <p className="text-left montserrat-light mt-3 mb-4">{getTextShort(novelty.excerpt,(isHome ? 80 : 120))}</p>
                    </div>
                    <div className="div_pill d-flex flex-wrap mb-4">
                        {novelty.tags.map((item, index) => (
                            index < tags_count ?
                                <span className="pill">{item.name}</span>
                            :''
                        ))}
                    </div>
                    <hr />
                    { (novelty?.author_choices && (novelty?.author_choices !== 'unspecified'))
                        ? <span class={'publicated_by ' + novelty?.author_choices}>
                            <p className="font-0 text-type-1">
                                Publicado por {novelty?.author}
                            </p>
                          </span>
                        : <div class="no-author-space"></div> }
                    {/* <span className="publicated_by"><p className="font-0 text-type-1">Publicado por: <br />{novelty.user.name}</p></span> */}
                    <Link  onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))} className="see_more d-flex" to={settings.base_url + "novedades/" + novelty.id + "/" + novelty.slug}>
                        <span className="text-uppercase montserrat-bold fs-23">Lee más</span> 
                        {/* <p>→</p> */}
                    </Link>
                </div>
            </div>
        </div>
    )
} 

export default Card_news