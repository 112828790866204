// constantes
const dataInicial = {
    name:'Flamma',
    short_name:'flamma',
    // base_url:'/clientes/red-react/',
    base_url:'/',
    // client_id:31,
    social:
    {
        facebook:'https://www.instagram.com/flamma.re/',
        instagram:'https://www.instagram.com/flamma.re/',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['' , '000-000000'],
        whatsapp:{link:'+5491150649909',visual:'11 5064 9909'},
        mail:'info@flamma.ar'
    },
    location:
    {
        address:'Nueva York 4302',
        region:'Villa Devoto, Argentina',
        cp:'0000'
    },
    keys:{
        captcha:'6Lel-rodAAAAAH3vs9192XBf4sd_bSWNAjtqUX53',
        tokko:'2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b',
        google_tgm:'',
        google_map:'AIzaSyCelKXL-cdJKcw8uWLWDGFrL6tZFASQ5uY',
        ga_analytics_mediacore:'G-8V3TFTZH7K'
    },
    seo:{
        title:'Flamma - Real Estate Partners',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}