import { useEffect ,useState } from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom"
import {shortDirection} from '../../functions/functions_property'
import {get_shortDescription, getGroup_CustomTag,group_Unities} from '../../functions/functions_dev'
import circleBg from '../../assets/images/elipse_bg_emprendimiento.svg';

// const Data = () => {
//     // Store redux 
//     const settings = useSelector(store => store.settings)
//     const { data } = useSelector((store) => store.developments.development);
//     const unities = useSelector((store) => store.developments.units_development).data;
//     const [group,setGroup] = useState()
//     const [load,setLoad] = useState(false)

//     const [filters,setFilters] = useState(0)

//     const [isArrowClicked, setIsArrowClicked] = useState(false)

//     useEffect(() => {
//         if(unities){
//             setGroup(group_Unities(unities.objects,filters))
//             setLoad(true)
//         }
//     },[unities,filters])


//     const updateFilter = (value) => {
//         if(value===filters){return setFilters(0)}
//         return setFilters(value)
//     }

//     const validateGroup = () => {
//         if(!group){return false}
//         if(group.length===0){return false}
//         console.log(group)
//         return true
//     }



//   // Render
//   return unities && group ? (
//     <>
//         <section className={"mt-lg-5 pt-lg-5 container unities position-relative w-100 overflow-hidden" + (unities.meta.total_count === 0 || group.length === 0 && filters === 0 ? ' d-none ' : '')} id="unidades">
//                 {console.log(unities?.objects,group)}
//                 <div style={{textAlign: "center", marginBottom: "3em"}}>
//                     <h2 style={{letterSpacing:"2px"}} className="fs-50 montserrat-bold mb-lg-4">UNIDADES</h2>
//                     <p className="montserrat-light fs-24" style={{ color: '#C1BCBC' }}>Seleccione el tipo de unidad que está buscando</p>
//                     {/* <header className=" section-header wow fadeInLeft " style={{width: "100px",marginBottom: "-50px"}}>
//                         <strong className="fade-title-left " style={{zIndex:"-99"}}>Unidades</strong>
//                     </header> */}
//                     <div className="d-flex justify-content-center mt-5">
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 1 ? ' active ' : '')} onClick={() => updateFilter(1)}>1 Ambiente</div>
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 2 ? ' active ' : '')} onClick={() => updateFilter(2)}>2 Ambientes</div>
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 3 ? ' active ' : '')} onClick={() => updateFilter(3)}>3 Ambientes</div>
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 4 ? ' active ' : '')} onClick={() => updateFilter(4)}>4 Ambientes</div>
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 5 ? ' active ' : '')} onClick={() => updateFilter(5)}>5 Ambientes</div>
//                         <div className={"tag-filter proyect-bg me-3" + (filters === 6 ? ' active ' : '')} onClick={() => updateFilter(6)}>6 Ambientes</div>
//                     </div>
//                 </div>
//                 <div className="col-10 m-auto" style={{marginTop: "7em"}}>
//                 {
//                     group && load ? 
//                     group.map((item,index) => (
//                             <div className="unity collapsed" data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
//                                 <div className="head-row row">
//                                     <div className="col-lg-2 col-3">
//                                         <span className="unity-font">{item.floor}</span>
//                                     </div>
//                                     <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
//                                         <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
//                                     </div>
//                                     <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
//                                         <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. cubierta </span>
//                                     </div>
//                                     <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
//                                         <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
//                                     </div>
//                                     <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
//                                         <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
//                                     </div>
//                                     <div className="col-1" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
//                                         <i className={'icon-arrow-up'}></i>
//                                     </div>
//                                 </div>
//                                 <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
//                                         {/* {item.unities.sort(function(a, b){return a.address.split(" ")[1] - b.address.split(" ")[1]}).map((element,index) => ( // NO ANDA??                            (element.status === 2 ? */}
//                                         {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
//                                             (element.status === 2 ?
//                                             <>
//                                                 <Link to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
//                                                     <div className={"unity-row row " + (element.status !== 2 ? 'disabled' : '')} >
//                                                         <div className="col-lg-2 col-3">
//                                                             <span className={"unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
//                                                         </div>
//                                                         <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
//                                                             <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
//                                                         </div>
//                                                         <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
//                                                             <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
//                                                         </div>
//                                                         <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
//                                                             <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
//                                                         </div>
//                                                         <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
//                                                             <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
//                                                         </div>
//                                                         <div className="col-1"></div>
//                                                     </div>
//                                                 </Link>
//                                             </>
//                                         :
//                                             <>
//                                                 <div to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
//                                                     <div className={"unity-row row disabled"} >
//                                                         <div className="col-lg-6 col-3">
//                                                             <span className={"unity-yellow-font proyect-color"}>{element.address}  </span>
//                                                         </div>
//                                                         <div className="col-lg-2 col-3" style={{textAlign:'center',transform:"rotate(0deg)"}}>
//                                                             <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
//                                                         </div>
//                                                         <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
//                                                             <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
//                                                         </div>
//                                                         <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
//                                                             <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
//                                                         </div>
//                                                         <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
//                                                             <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
//                                                         </div>
//                                                         <div className="col-1"></div>
//                                                     </div>
//                                                 </div>
//                                             </>
//                                         )
//                                     ))}
//                                 </div>
//                             </div>
//                         ))
//                     : //Están cargando los desarrollos
//                     <>
//                         <div class="loader relative">
//                             <div class="page-lines">
//                                 <div class="container">
//                                 <div class="col-line col-xs-4">
//                                     <div class="line"></div>
//                                 </div>
//                                 <div class="col-line col-xs-4">
//                                     <div class="line"></div>
//                                 </div>
//                                 <div class="col-line col-xs-4">
//                                     <div class="line"></div>
//                                     <div class="line"></div>
//                                 </div>
//                                 </div>
//                             </div>
//                             <div class="loader-brand">
//                                 <div class="sk-folding-cube">
//                                 <div class="sk-cube1 sk-cube"></div>
//                                 <div class="sk-cube2 sk-cube"></div>
//                                 <div class="sk-cube4 sk-cube"></div>
//                                 <div class="sk-cube3 sk-cube"></div>
//                                 </div>
//                             </div>
//                         </div>
//                     </>
//                 }
//             </div>
//             <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
//                 <p className="fs-18 disabled-unities montserrat-regular text-center" style={{color: "#fff" , margin: "3rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
//             </div>
//             <div className="col-10 m-auto">
//                 <p className="fs-18 disabled-unities montserrat-light text-start" style={{color: "#fff" , margin: "8rem 0", maxWidth: ""}}>* Las unidades inactivas  no están disponibles.</p>
//             </div>
//         </section>
//         <img className="image__unities__bg position-absolute d-lg-block" src={ circleBg } alt="dblcircle" />
//     </>
//   ):''
// };

// export default Data;

const Data = () => {
    // Store redux 
    const settings = useSelector(store => store.settings)
    const { data } = useSelector((store) => store.developments.development);
    const unities = useSelector((store) => store.developments.units_development).data;
    const [group,setGroup] = useState()
    const [load,setLoad] = useState(false)

    const [filters,setFilters] = useState(0)

    const [isArrowClicked, setIsArrowClicked] = useState(false)

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities.objects,filters))
            setLoad(true)
        }
    },[unities,filters])


    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    const validateGroup = () => {
        if(!group){return false}
        if(group.length===0){return false}
        console.log(group)
        return true
    }



  // Render
  return unities && group ? (
    <>
        <section className="div_unidades ">
            <div className="container-fluid fluid-1">
                <h2 className="section-title text-center">Unidades</h2>
            </div>
            <div className="container-fluid fluid-2">
                <h3 className="text-center montserrat-light">Seleccione el tipo de unidad que está buscando</h3>
                <section className={"mt-lg-5 pt-lg-5 row justify-content-center unities position-relative overflow-hidden" + (unities.meta.total_count === 0 || group.length === 0 && filters === 0 ? ' d-none ' : '')} id="unidades">
                    <div className="div_ambientes text-center mb-3 col-lg-10" >
                        
                        {/* <header className=" section-header wow fadeInLeft " style={{width: "100px",marginBottom: "-50px"}}>
                            <strong className="fade-title-left " style={{zIndex:"-99"}}>Unidades</strong>
                        </header> */}
                        <div className="div_tags row my-2 my-lg-5 ">
                            <div className={" col-4 col-lg-2 p-2 mt-1   " + (filters === 1 ? ' active ' : '')} onClick={() => updateFilter(1)}> <div className={"tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 1 ? ' active ' : '')}>1 Ambiente</div></div>
                            <div className={" col-4 col-lg-2 p-2 mt-1  " + (filters === 2 ? ' active ' : '')} onClick={() => updateFilter(2)}><div className={"tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 2 ? ' active ' : '')}>2 Ambientes</div></div>
                            <div className={" col-4 col-lg-2 p-2 mt-1  " + (filters === 3 ? ' active ' : '')} onClick={() => updateFilter(3)}><div className={"tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 3 ? ' active ' : '')}>3 Ambientes</div></div>
                            <div className={" col-4 col-lg-2 p-2 mt-1  " + (filters === 4 ? ' active ' : '')} onClick={() => updateFilter(4)}><div className={ "tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 4 ? ' active ' : '')}>4 Ambientes</div></div>
                            <div className={" col-4 col-lg-2 p-2 mt-1  " + (filters === 5 ? ' active ' : '')} onClick={() => updateFilter(5)}><div className={"tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 5 ? ' active ' : '')}>5 Ambientes</div></div>
                            <div className={" col-4 col-lg-2 p-2 mt-1  " + (filters === 6 ? ' active ' : '')} onClick={() => updateFilter(6)}><div className={"tag-filter p-1 p-lg-2 montserrat-regular" + (filters === 6 ? ' active ' : '')}>6 Ambientes</div></div>
                        </div>
                    </div>
                    <div className="div_pisos col-10 m-auto ">
                        {
                            group && load ? 
                            group.map((item,index) => (
                                    <div className="unity collapsed" data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                        <div className="head-row row">
                                            <div className="col-lg-2 col-3">
                                                <span className="unity-font  ">{item.floor}</span>
                                            </div>
                                            <div className="col-lg-2 col-3 d-none d-lg-block text-center" >
                                                <span className="unity-font sub-title text-center" >Ambientes</span>
                                            </div>
                                            <div className="col-lg-3 col-4 d-none d-lg-block text-center" >
                                                <span className="unity-font sub-title text-center" >Sup. cubierta </span>
                                            </div>
                                            <div className="col-lg-2 col-4 text-center">
                                                <span className="unity-font sub-title text-center" >Sup. Total </span>
                                            </div>
                                            <div className="col-lg-2 col-4 text-center" >
                                                <span className="unity-font sub-title text-center" >Precio </span>
                                            </div>
                                            <div className={'col-1 col_arrow'} style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                {/* <i className={'icon-arrow-light'  + (isArrowClicked ? ' active_arrow' : '')}></i> */}
                                                <i className={'icon-arrow-light'}></i>
                                            </div>
                                        </div>
                                        <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                                {/* {item.unities.sort(function(a, b){return a.address.split(" ")[1] - b.address.split(" ")[1]}).map((element,index) => ( // NO ANDA??                            (element.status === 2 ? */}
                                                {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
                                                    (element.status === 2 ?
                                                    <>
                                                        <Link to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
                                                            <div className={"unity-row row " + (element.status !== 2 ? 'disabled' : '')} >
                                                                <div className="col-lg-2 col-3">
                                                                    <span className={" unity-font proyect-color text-center montserrat-regular"}>{element.address}</span>
                                                                </div>
                                                                <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                    <span className="unity-font proyect-color text-center montserrat-regular" >{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font proyect-color text-center montserrat-regular" >{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font proyect-color text-center montserrat-regular" >{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font proyect-color text-center montserrat-regular" > {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                                </div>
                                                                <div className="col-1"></div>
                                                            </div>
                                                        </Link>
                                                    </>
                                                :
                                                    <>
                                                        <div to={settings.base_url + "unidad/" + shortDirection(element.operation,element.type,element.location,element.id,element.address)}>
                                                            <div className={"unity-row row disabled"} >
                                                            <div className="col-lg-2 col-3">
                                                                    <span className={" unity-font text-center"}>{element.address}</span>
                                                                </div>
                                                                <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                    <span className="unity-font text-center" >{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font text-center" >{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font text-center" >{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font text-center" > {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                                </div>
                                                                <div className="col-1"></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            ))}
                                        </div>
                                    </div>
                                ))
                            : //Están cargando los desarrollos
                            <>
                                <div class="loader relative">
                                    <div class="page-lines">
                                        <div class="container">
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                        </div>
                                        <div class="col-line col-xs-4">
                                            <div class="line"></div>
                                            <div class="line"></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="loader-brand">
                                        <div class="sk-folding-cube">
                                        <div class="sk-cube1 sk-cube"></div>
                                        <div class="sk-cube2 sk-cube"></div>
                                        <div class="sk-cube4 sk-cube"></div>
                                        <div class="sk-cube3 sk-cube"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                        <p className="fs-18 disabled-unities  text-center" style={{color: "#fff" , margin: "3rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                    </div>
                    <div className="col-10 m-auto div_unidad_inactiva">
                        <p className="fs-18 disabled-unities  text-start" >* Las unidades inactivas  no están disponibles.</p>
                    </div>
                </section>
                
            </div>
        </section>
        

    </>
  ):''
};

export default Data;