import React from "react"
import { useSelector } from "react-redux";

// Components
import AlertTop from "./Global/Modules/MediaPush/AlertTop";
import FlapButton from "./Global/Modules/MediaPush/FlapButton";
import CounterPopup from "./Global/Modules/MediaPush/CounterPopup";
import SquarePop from "./Global/Modules/MediaPush/SquarePop";

const getActionByType = (actions,type) => {
  return actions.filter(action => action.type.toLowerCase() === type.toLowerCase())
}

const Modules = () => {

  const actions = useSelector((store) => store.actions);
  // console.log(actions);

  const checkShowOn = (actionSection) => {
    if (actionSection === "Tasaciones") {
      return 'manifiesto'
    } else if (actionSection === "Emprendimientos") {
      return 'desarrollos'
    } else {
      return actionSection
    }
  }

  return (
    <>
      {getActionByType(actions?.actions,'Solapa Flotante').map((action => (
        <FlapButton 
        name={action.name}
        type={action.type}
        section={action.section}
        small_type={action.small_type}
        small_section={action.small_section}
        linkTo={action.texts.text_URL+action.texts?.text_utm}
        linkTxt={action.texts.text_limited}
        alignment={action.alignment}
        showOn={checkShowOn(action.section)}/>
      )))}
      {getActionByType(actions?.actions,'PopUp Timer').map((action => (
        <CounterPopup 
          linkTo={action.texts.text_URL + action.texts.text_utm} 
          linkTxt={action.texts.text_button} 
          title={action.title}
          showOn={checkShowOn(action.section)}
          band={action.texts.text_band} 
          text={action.texts.text}
          utmValue={action.texts.text_utm}
          name={action.name}
          small_type={action.small_type}
          small_section={action.small_section}
          eventDate={action.date_start}/> 
      )))}
      {getActionByType(actions?.actions,'PopUp Vertical').map((action => (
        <SquarePop
        align={action.alignment} 
        flat={false} 
        square={true}
        linkTo={action.url_destiny + action.text_utm}
        image={action.file}
        utmValue={action.text_utm}
        name={action.name}
        small_type={action.small_type}
        small_section={action.small_section}
        showOn={checkShowOn(action.section)} /> 
      )))}
      {getActionByType(actions?.actions,'PopUp Cuadrado').map((action => (
        <SquarePop
        align={""} 
        flat={false} 
        linkTo={action.url_destiny}
        image={action.file}
        name={action.name}
        small_type={action.small_type}
        small_section={action.small_section}
        utmValue={action.text_utm}
        showOn={checkShowOn(action.section)} /> 
      )))}
      {getActionByType(actions?.actions,'PopUp Panoramico').map((action => (
        <SquarePop
        align={""} 
        flat={true} 
        linkTo={action.url_destiny}
        image={action.file}
        name={action.name}
        small_type={action.small_type}
        small_section={action.small_section}
        utmValue={action.text_utm}
        showOn={checkShowOn(action.section)} /> 
      )))}
      {getActionByType(actions?.actions,'Barra Encabezado Timer').map((action => (
        <AlertTop 
        linkTo={action.texts.text_URL}
        name={action.name}
        small_type={action.small_type}
        small_section={action.small_section}
        linkTxt={action.texts.text_button}
        textAlert={action.texts?.text}
        showOn={checkShowOn(action.section)}
        utmValue={action.texts.text_utm}
        eventDate={action.date_start}/>
      )))}
      {getActionByType(actions?.actions,'Barra Encabezado').map((action => (
        <AlertTop 
        linkTo={action.texts.text_URL}
        linkTxt={action.texts.text_button}
        textAlert={action.texts?.text}
        utmValue={action.texts.text_utm}
        name={action.name}
        small_type={action.small_type}
        small_section={action.small_section}
        showOn={checkShowOn(action.section)}/>
      )))}
    </>
  )
}

export default Modules;