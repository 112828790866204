import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getDateTime, getFeatured } from '../../functions/MediaBlog/functions_novelties'
import { get_short_desc } from '../../functions/functions_dev'

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

import Breadcrumbs from '../breadcrumbs'
import Card_news from '../MediaBlog/Global/card_new'
import circlePrimary from '../../assets/images/elipse_bg_home_primary.svg'
import { Link } from 'react-router-dom';
import parse from "html-react-parser";


const Main = () => {

    const novelty = useSelector(state => state.novelties.novelty)
    const settings = useSelector(store => store.settings)
    const data = useSelector(state => state.novelties.grid_novelties)
    
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
    useEffect(() => {
        if(novelty){
          if(novelty.content){
            const blocksFromHtml = htmlToDraft(novelty.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState))
        }
        window.scrollTo(0, 0);
        }
      },[novelty])

    return novelty ?  (
        <main class="main">
            <div class="container-fluid-85 row">
                <div className="div_breadcrums mb-lg-5">
                    <Breadcrumbs   
                    props={[
                        { name: "Home", route: settings.base_url, location: "" },
                        { name: "Novedades", route:  settings.base_url + "novedades", location: "" },
                        {
                            name: novelty.title ? get_short_desc(novelty?.title,30) : 'Cargando..',
                            route:'',
                            active:true
                        }
                    ]}
                    />
                </div>
                <div className="primer_bloque col-12 col-lg-6 mb-5 mb-lg-3">
                    <div className="div_novedad"> 
                        <span className="fecha sspro-light opacity-50 fs-20 ">
                            {novelty.date_published ? getDateTime(novelty.date_published,true) : 'Cargando..'}
                        </span>
                        { novelty?.image 
                            ? <div className="banner-container mt-4"> 
                                <img className="banner-image" src={novelty.image} alt={novelty.title} />
                                <div className="banner-caption">{novelty.footer}</div>
                              </div> : null }
                        <h2 className="titulo fs-40 montserrat-bold mt-5">{novelty.title}</h2>
                        <h3 className="subtitulo fs-20 montserrat-semibold mt-4 mb-4 text-start">{novelty.headline}</h3>
                        {/* <h4 className="publicado_por sspro-light fs-18 opacity-25 mt-5">
                            Publicado por
                            { novelty.creator
                                ? ` ${novelty.creator.first_name} ${novelty.creator.last_name}`
                                : 'Cargando...' }
                        </h4> */}
                        { (novelty?.author_choices && (novelty?.author_choices !== 'unspecified'))
                        ? <h4 class={'publicado_por sspro-light fs-18 opacity-25 mt-5 ' + novelty?.author_choices}>
                            Publicado por {novelty?.author}</h4>
                        : null }
                        {/* <p className="texto_novedad sspro-light fs-24 opacity-75">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                disabled={true}
                            />
                        </p> */}
                        <div className="div_body_novelty">
                            {novelty.content ? <div> {parse(novelty.content)}</div> : ""}
                        </div>
                        <hr className="hr_novedad mt-5 mb-5" />
                        <div className="pills d-flex flex-wrap">
                            {novelty.tags ? 
                                novelty.tags.map((element, index) => (
                                    <span className="pill" key={index}>{element.name}</span>
                                )) : null }
                        </div>
                        <Link to="/novedades" className="mt-lg-3 mt-2 buttonAnimation">Volver a novedades</Link>
                    </div>
                </div>
                <div className="segundo_bloque col-12 offset-lg-1 col-lg-5 p-0 p-lg-5 ">
                    <div className="position-relative mt-5">
                    <img className="circlePrimary d-none d-lg-block" src={circlePrimary} alt="svgCirclePrimary" />
                        <h2 className="titulo fs-25 montserrat-semibold">Novedades destacadas</h2>
                        <div className="div_novedades_destacadas mt-5">
                            <div className="row">
                                {data ? 
                                    (getFeatured(data).map((element, index) => (
                                        <div className="col-12 mt-5 mb-5" key={index}>
                                            <Card_news novelty={element} />
                                        </div> 
                                    ))) : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    ) :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main