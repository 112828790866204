import React from 'react'
// import arrowTop from '../../assets/images/arrow_up.svg'; 
// import elipse_bg from '../../assets/images/elipse_bg.svg'
import { Form } from '../form/Form'
// import Map from '../map'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {useLocation} from "react-router-dom";

const latitud = {latitud: -34.57565921221931, longitud: -58.420479848902474}

export const SectionForm = () => {
  const { data } = useSelector((store) => store.developments.development);
  const unity = useSelector((store) => store.properties.property).data;
  const settings  = useSelector((store) => store.settings);
  const location = useLocation();

  const checkView = () => {
    if(location.pathname.split(settings.base_url).length === 1){
      return false
    }
    if(location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo' || location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      return true
    } else {
      return false
    }
  }

  const [name,setName] = useState('');

  useEffect(() => {
    if(data && location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo'){
      setName(data.name.replace(/ /g, "-").toLowerCase())
    }
    else if(unity && location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      setName(unity.development.name.replace(/ /g, "-").toLowerCase())
    }
    else{
      setName('')
    }
  },[location,data,unity])

  

  return  (
    <section id="contacto" className={"section__five " + name }>
      <div className="col-10 m-auto d-flex flex-column justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-center align-items-center position-relative w-100">
          <p className="section__five__title montserrat-semibold">¿Queres más <span className="texto-primary proyect-color">información?</span></p>
          <a onClick={() => window.scrollTo( 0, 0 )}  className="section__five__arrow__top d-xl-block">
            <i className="proyect-color icon-arrow-up"></i>
          </a>
        </div>
        <div className="row section__five__container flex-lg-row flex-column align-items-lg-baseline align-items-center justify-content-center w-100">
          <div className="col-lg-4 my-lg-5 my-3 d-flex align-items-center flex-column justify-content-center justify-content-lg-start align-items-lg-start ">
            <ul className="contact">
              <li className="contact__item sspro-light">
                {settings.location.address}
              </li>
              <li className="contact__item sspro-light">
                {settings.location.region}
              </li>
              <li className="contact__item sspro-light">
                <a href={"https://web.whatsapp.com/send?&phone=" + settings.contact.whatsapp.link}>
                  Wa. {settings.contact.whatsapp.visual}
                </a>
              </li>
            </ul>
            {/* <a href={"mailto:" + settings.contact.mail} className="texto-primary contact__email sspro proyect-color">{settings.contact.mail}</a> */}
          </div>
          <Form />
        </div>
      </div>
      {/* <img className="elipse_bg d-xl-block" src={ elipse_bg }  alt="elipse_bg" /> */}
    </section>
  )
}
